import { render, staticRenderFns } from "./StoresList.vue?vue&type=template&id=40c08718&scoped=true&"
import script from "./StoresList.vue?vue&type=script&lang=js&"
export * from "./StoresList.vue?vue&type=script&lang=js&"
import style0 from "./StoresList.vue?vue&type=style&index=0&id=40c08718&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c08718",
  null
  
)

export default component.exports