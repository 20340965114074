var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stores__container"},[_c('h1',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm.title))]),(_vm.loading)?_c('section',{staticClass:"stores__grid"},_vm._l((20),function(n){return _c('b-skeleton-img',{staticClass:"store__skeleton",attrs:{"height":"175px"}})}),1):_c('div',{staticClass:"mt-2"},[(_vm.userData.role_name === 'establishment_presale_clerk')?_c('ul',{staticClass:"stores__grid"},_vm._l((_vm.filteredStores),function(store){return _c('b-card',{key:store.id,staticClass:"mt-1 text-center store__card py-2",on:{"click":function($event){return _vm.handlePresaleRedirect(store)}}},[_c('div',{staticClass:"d-flex justify-content-center mt--5"},[_c('span',{on:{"click":function($event){return _vm.handleLogoRedirect(store.id)}}},[_c('b-avatar',{staticClass:"cursor-pointer",attrs:{"src":("" + (store.logo)),"size":"60"}})],1)]),_c('h5',{staticClass:"store-name"},[_vm._v(" "+_vm._s(store.name)+" ")]),_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-badge',{attrs:{"variant":"light-secondary"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"BoxIcon"}}),_vm._v(" "+_vm._s(store.store_products_count)+" ")],1)],1)])}),1):(
        _vm.userData.role_name === 'admin' ||
        _vm.userData.role_name === 'establishment_admin'
      )?_c('ul',{staticClass:"stores__grid"},[(!_vm.tab)?_c('b-card',{staticClass:"store__card"},[_c('div',{staticClass:"\n            text-center\n            justify-content-center\n            d-flex\n            flex-column\n            align-items-center\n          ",on:{"click":function($event){return _vm.$router.push({
              name: 'new-store',
              params: { id: _vm.$route.params.id },
            })}}},[_c('div',{staticClass:"d-flex justify-content-center mb-1 add__icon mt--5"},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"24"}})],1),_c('div',{staticClass:"establishment-name"},[_vm._v("Agregar")]),_c('div',{staticClass:"establishment-name"},[_vm._v("Tienda")])])]):_vm._e(),_vm._l((_vm.stores),function(store){return _c('b-card',{key:store.id,staticClass:"store__card",on:{"click":function($event){return _vm.handleShowStoreModal(store)}}},[_c('div',{staticClass:"mt-1 text-center"},[_c('div',{staticClass:"d-flex justify-content-center mt--5"},[_c('span',[_c('b-avatar',{staticClass:"cursor-pointer",attrs:{"src":("" + (store.logo)),"size":"60"}})],1)]),_c('div',{staticClass:"dropdown settings-btn"},[_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","toggle-class":"p-0","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"SettingsIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                  name: 'walleat-add-store-credit',
                  params: { store_id: store.id },
                }}},[_vm._v(" Añadir saldo a la tienda ")]),_c('b-dropdown-item',{attrs:{"to":{ name: 'store-users', params: { id: store.id } }}},[_vm._v(" Gestionar usuarios ")]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'store-suppliers',
                  params: {
                    establishment_id: _vm.$route.params.id,
                    store_id: store.id,
                  },
                }}},[_vm._v(" Gestionar proveedores ")]),_c('b-dropdown-item',{on:{"click":function($event){$event.stopPropagation();return _vm.handleShareStoreModal(store.id)}}},[_vm._v(" Compartir tienda ")]),_c('b-dropdown-divider'),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'edit-store',
                  params: { id: _vm.$route.params.id, store_id: store.id },
                }}},[_vm._v(" Editar tienda ")]),_c('b-dropdown-item',{attrs:{"to":{
                  name: 'edit-store-csd',
                  params: { id: _vm.$route.params.id, store_id: store.id },
                }}},[_vm._v(" Gestionar CSD ")])],1)],1),_c('h5',{staticClass:"store-name"},[_vm._v(" "+_vm._s(store.name)+" ")]),_c('div',{staticClass:"d-flex align-items-center justify-content-center mb-1"},[_c('b-badge',{attrs:{"variant":"light-secondary"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"BoxIcon"}}),_vm._v(" "+_vm._s(store.store_products_count)+" ")],1)],1)])])})],2):_vm._e()]),_c('b-modal',{attrs:{"id":"shareStoreModal","hide-footer":""}},[_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"\n          m-2\n          justify-content-center\n          d-flex\n          flex-column\n          align-items-center\n          text-center\n        "},[_c('h3',[_vm._v("Compartir tienda")]),_c('qriously',{attrs:{"value":_vm.qrcodeValue,"size":200}}),(_vm.webShare)?_c('b-button',{attrs:{"variant":"success"},on:{"click":_vm.shareOnSocialMedia}},[_vm._v(" Compartir por redes sociales ")]):_vm._e(),(!_vm.webShare)?_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"my-1"},[_c('h5',{staticClass:"mb-50"},[_vm._v("Comparte el link de la tienda")]),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.copyLink}},[_vm._v(" Copiar link ")])],1),_c('b-form-group',{attrs:{"label":"Correo electrónico:","label-for":"emailToShare"}},[_c('b-form-input',{staticClass:"my-1 w-100 mx-auto",attrs:{"id":"emailToShare","required":""},model:{value:(_vm.emailToShare),callback:function ($$v) {_vm.emailToShare=$$v},expression:"emailToShare"}})],1),_c('a',{attrs:{"href":_vm.generatedMail}},[_vm._v(" Envia por correo ")])],1):_vm._e()],1)])],1),_c('store-modal',{attrs:{"store":_vm.selectedStore,"establishment":_vm.establishment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }